<template>
  <div :class="{ uploading: isUploading }">
    <div class="flex mb-6 mt-6 items-center justify-center bg-grey-lighter">
      <label
        class="w-full flex flex-col items-center px-4 py-6 bg-white text-blue-600 rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-600 hover:text-white"
        :class="requiredClass"
      >
        <!-- Default icon -->
        <svg
          v-if="!isUploading"
          class="w-8 h-8"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
          />
        </svg>

        <!-- Uploading spinner -->
        <svg
          v-else
          class="animate-spin h-8 w-8"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>

        <!-- Button text -->
        <span class="mt-2 text-base leading-normal">{{
          uploadButtonTitle
        }}</span>
        <input
          type="file"
          class="hidden"
          :accept="accept.join(',')"
          v-on:change="onFileChanged"
        />
      </label>
    </div>

    <!-- Error message -->
    <div role="alert" v-if="hasError">
      <div class="bg-red-500 text-white font-bold rounded-t px-4 py-2">
        Upload Failed
      </div>
      <div
        class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700"
      >
        <ul>
          <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadVisualFormUserAttachment } from "@/api/nodes";
// import __ from "@/translation";

export default {
  name: "FileUpload",
  props: {
    /**
     * The key in which the MIME output will be stored in the v-model object
     */
    mimeOutputKey: {
      required: false,
      type: String
    },

    /**
     * The key in which the URI output will be stored in the v-model object
     */
    uriOutputKey: {
      required: true,
      type: String
    },

    /**
     * Object that will store the MIME output and URI output
     */
    value: {
      required: true,
      type: Object
    },

    /**
     * Allowed MIME types
     */
    accept: {
      required: false,
      type: Array,
      default: () => []
    },

    /**
     * The current visual form session ID
     */
    sessionId: {
      required: true,
      type: String
    },

    /**
     * Name of the field. Will be used for validation in the backend
     */
    name: {
      required: true,
      type: String
    },
    requiredClass: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      myId: new Date().valueOf(),
      isUploading: false,
      errors: [],
      selectedFileName: ""
    };
  },

  computed: {
    /**
     * Getter & setter for MIME output
     */
    mimeDestination: {
      get() {
        if (this.mimeOutputKey === undefined) {
          return "";
        }

        return this.value[this.mimeOutputKey];
      },

      set(newVal) {
        if (this.mimeOutputKey === undefined) {
          return;
        }

        this.updateContent(this.mimeOutputKey, newVal);
      }
    },

    /**
     * Getter & setter for URI output
     */
    uriDestination: {
      get() {
        return this.value[this.uriOutputKey];
      },

      set(newVal) {
        this.updateContent(this.uriOutputKey, newVal);
      }
    },

    /**
     * Check if upload has error
     */
    hasError() {
      return this.errors.length > 0;
    },

    /**
     * Title for the upload button
     */
    uploadButtonTitle() {
      return this.selectedFileName === ""
        ? "Select a file"
        : this.selectedFileName;
    }
  },

  methods: {
    /**
     * Broadcast input event with the new content as the event argument
     */
    triggerContentUpdate(content) {
      this.$emit("input", content);
    },

    /**
     * Update the content with the given key and trigger an input event
     */
    updateContent(contentKey, contentValue) {
      let newContent = this.value;
      newContent[contentKey] = contentValue;
      this.triggerContentUpdate(newContent);
    },

    /**
     * Reset upload loading state
     */
    resetLoadingState() {
      this.errors = [];
      this.isUploading = false;
    },

    /**
     * Prepare user file for upload and trigger the upload routine
     */
    onFileChanged(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      // Update the selected file name
      this.selectedFileName = files[0].name;

      // Attach all files for upload
      let formData = new FormData();
      formData.append("fileAttachment", files[0]);

      // Attach the session ID
      formData.append("sessionId", this.sessionId);

      // Attach the field type
      formData.append("fieldName", this.name);

      this.startUpload(formData);
    },

    /**
     * Build the error message given an error object
     * @param { Object } error
     * @returns { String[] } Series of error messages
     */
    buildErrorMessage(error) {
      // We don't want to display exceptions.
      // If backend returns 413 Payload Too Large, the API handler assumes that
      // data is included in the response. This will throw an exception since when
      // error 413 is returned, the body of the response is empty.
      if (
        error.__proto__ instanceof Error ||
        !error.message ||
        typeof error.message !== "string"
      ) {
        return ["Unable to process your request."];
      }

      return error.message
        .split(". ")
        .map(message => `${message.replace(".", "")}.`);
    },

    /**
     * Start the upload process
     * @param {FormData} formData
     */
    async startUpload(formData) {
      // Reset upload state
      this.resetLoadingState();

      this.isUploading = true;
      this.$emit("startUpload", this.myId);

      let remoteDetail = undefined;

      try {
        remoteDetail = await uploadVisualFormUserAttachment(formData);
      } catch (error) {
        this.errors = this.buildErrorMessage(error);
      } finally {
        this.$emit("finishUpload", this.myId);
        this.isUploading = false;
      }

      // Cant update file detail if remoteDetail is empty
      if (remoteDetail === undefined) {
        return;
      }

      // Try to set the new values
      if (remoteDetail && remoteDetail.status === 200 && remoteDetail.data) {
        this.uriDestination = remoteDetail.data.remote_url;
        this.mimeDestination = remoteDetail.data.file_type;
      }
    }
  }
};
</script>
